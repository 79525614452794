import React from 'react';
import { Button } from '@mantine/core';
import { useObjectStore } from "../zustand/objects";

const copyDynamicTableToClipboard = (screenshots, artboards, storyboardFields) => {
  const chunkObject = (obj, size) => {
      const chunks = [];
      const keys = Object.keys(obj);
      for (let i = 0; i < keys.length; i += size) {
          const chunk = {};
          keys.slice(i, i + size).forEach(key => {
              chunk[key] = obj[key];
          });
          chunks.push(chunk);
      }
      return chunks;
  };

  // Chunk the data into smaller groups of up to 4
  const screenshotChunks = chunkObject(screenshots, 4);
  const artboardChunks = chunkObject(artboards, 4);
  const storyboardFieldChunks = chunkObject(storyboardFields, 4);

  // Dynamically generate HTML for the tables
  const tableHTML = screenshotChunks.map((chunk, index) => `
    <table border="1" style="border-collapse: collapse; margin-bottom: 16px; text-align: center; width: auto; display: inline-table;">
      <tbody style={"width: 100px, padding: 0px}>
        <tr>
          ${Object.keys(chunk).map(id => `
            <td style="padding: 0px;">
              <img src="${chunk[id]}" alt="Screenshot ${id}" style="width: 144px; height: 100px;" />
            </td>
          `).join('')}
        </tr>
        <tr>
          ${Object.keys(storyboardFieldChunks[index] || {}).map(id => `
            <td><b>${id}: ${storyboardFieldChunks[index][id].name}</b></td>
          `).join('')}
        </tr>
        <tr>
          ${Object.keys(storyboardFieldChunks[index] || {}).map(id => `
            <td>${storyboardFieldChunks[index][id].description}</td>
          `).join('')}
        </tr>
        <tr>
          ${Object.keys(artboardChunks[index] || {}).map(id => `
            <td><a target="_blank" href="https://mbxr.io/share/5bb83?artboard=${id}">View preview</a></td>
          `).join('')}
        </tr>
      </tbody>
    </table>
  `).join('');

  // Copy the table to the clipboard as HTML
  const blob = new Blob([tableHTML], { type: 'text/html' });
  const clipboardItem = new ClipboardItem({ 'text/html': blob });

  navigator.clipboard.write([clipboardItem])
      .catch(err => {
          console.error('Failed to copy the table:', err);
          alert('Failed to copy the storyboard table to the clipboard.');
      });
};


// React Component Example
const CopyStoryboardTable = () => {
  const { screenshots, artboards, storyboardFields } = useObjectStore();

  const handleCopy = () => {
      copyDynamicTableToClipboard(screenshots, artboards, storyboardFields);
  };

  return (
      <div>
          <Button
              style={{ backgroundColor: "#2d9bf0", boxShadow: "none", color: 'black', borderRadius: 0, borderStyle: "outset", padding: 4 }}
              onClick={handleCopy}
          >
              Copy as a Table
          </Button>
      </div>
  );
};

export default CopyStoryboardTable;
