import React, { useEffect, useCallback, Suspense, useState, useRef } from "react";
import debounce from 'lodash.debounce';
import { Link, useParams } from "react-router-dom";
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CssBaseline from '@mui/material/CssBaseline'; 
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme } from '@mui/material/styles';
import { saveAs } from "file-saver";
import { GLTFExporter } from "three/examples/jsm/exporters/GLTFExporter";
import { OBJExporter } from "three/examples/jsm/exporters/OBJExporter";

import { View } from "@react-three/drei";
import { Canvas, useThree, useFrame } from "@react-three/fiber";


import DownloaderFile from "../components/DownloaderFile";
import { useObjectStore } from "../zustand/objects";
import AppNavBar from "../components/AppNavBar";
import StoryboardTextField from "../components/StoryboardTextField"
import { Screenshot, ScreenshotStoryboardPOV } from "../components/Screenshot";
import Tooltip from '@mui/material/Tooltip';
import ModelAttributions from "../ModelAttributions.json";
import ViewAttribution from "../components/ViewAttribution";
import CopyStoryboardTable from "../components/CopyStoryboardTable";
import {ExportToAPIButton} from "../components/TopProjectAppBar.js";
import { saveUserScreenshotsAPI } from "../api.js";

import { Card, Button, Modal, Center, Loader, LoadingOverlay, Input, Textarea, Text, Group, Badge, ActionIcon } from '@mantine/core';

export default function EditorStoryboardPage({ match }) {
  const { objectsAreLoaded, storyboardFields, updateArtboardAndPreviewCamera, currentObjectArtboard, setScreenshotStatus, screenshotStatus, unloadObjects, loadObjects, exportData, artboards, getSpecificUserProject, projectName, updateProjectName, projectUserName, updateProjectUserName, screenshots, updateProjectUserActivity, projectUserOutcome, updateProjectUserOutcome, getAIModels } = useObjectStore();

  const [open, setOpen] = useState(false);
  

  
  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }
  const { projectKey } = useParams();

  async function unloadProject() {
    unloadObjects()
  }


  // HOW TO CYCLE ALL SCREENSHOTS IN BATCHES OF FOUR
  
const inputArtboards = artboards && Object.keys(artboards)
const groupSize = 4;
const [result, setResult] = useState([])
const [currentSnappingArtboard, setCurrentSnappingArtboard] = useState(null)
const [snapping, setSnapping] = useState(false)

// const [groupsReady, setGroupsReady] = useState(false)

function groupArtboards() {
  const tempResult = []
  for (let i = 0; i < inputArtboards.length; i += groupSize) {
    tempResult.push(inputArtboards.slice(i, i + groupSize));
  }
  setResult([...tempResult])  
}
 
useEffect(() => {
    unloadProject().then(() => 
    getSpecificUserProject(projectKey)).then(() => 
    getAIModels())
    .then(result => loadObjects())
    .then(result => updateArtboardAndPreviewCamera(1))
    // .then(result => setScreenshotStatus(true))    
  }, []); // <-- empty dependency array

  const handleSubmitProjectName = useCallback(debounce((val) => {
    updateProjectName(val)
  }, 500))
  const handleSubmitProjectUserName = useCallback(debounce((val) => {
    updateProjectUserName(val)
  }, 500))
  const handleSubmitProjectUserActivity = useCallback(debounce((val) => {
    updateProjectUserActivity(val)
  }, 500))
  const handleSubmitProjectUserOutcome = useCallback(debounce((val) => {
    updateProjectUserOutcome(val)
  }, 500))


  const GridBackground = () => {
    return (
      <div style={{ position: "fixed", left: 0, top: 0, bottom: 0, zIndex: -1, width: "100%", height: "120%", backgroundImage: 'url("/image/background-grid.png")', backgroundAttachment: "fixed" }}></div>

    )
  }


  // iterate functionality
    const [artboardNumber, setArtboardNumber] = useState('1')
    
    function runIteration(i = 1) {
       // The total number of artboards
  const totalArtboards = Object.keys(artboards).length;
  
  // Base case: if i > totalArtboards, we're done
  if (i > totalArtboards) {
    return
    // Maybe do your "reset" or "cleanup" here:
   
  }
  
  // Update to the current artboard
  setArtboardNumber(i);
  
  // Wait some delay, then move to the next artboard
  setTimeout(() => {
    runIteration(i + 1);
  }, 1000);
}


const removeGroup = () => {
  setSnapping(false)
}

  const updateAllScreenshots = () => {
    unloadProject().then(() =>
      getSpecificUserProject(projectKey)).then(() =>
        getAIModels())
      .then(result => loadObjects())
      .then(result => updateArtboardAndPreviewCamera(1))
      .then(result => setArtboardNumber('1'))
      .then(result => setSnapping(true))
      .then(result => setTimeout(() => {
        // Move to the next index
        runIteration()
      }, 1000))
  }

// if we stored screenshots in keyed object, we could ensure they go to the right place
// and go back and check if any have been missed, running it a second time
// storing it in state that's wiped at the beginning of the function, before then uploading
// would require xano changes

    // useEffect(() => {
    //   if (!objectsAreLoaded || !artboards) return
    //   updateAllScreenshots()
    //   // page === 'storyboard' && iterateFunction()
    // },[objectsAreLoaded])
 

const canvasRef = useRef()

// lazy load functionality


  return (<>
    <CssBaseline />
    <GridBackground />
    <AppNavBar pageName={projectName} position="relative">
      <Typography variant="h6" color="inherit" noWrap>
        Edit or share your storyboard
      </Typography>
    </AppNavBar>
    <Box
      sx={{
        pt: 8,
        pl: 0 
      }}
    >
      <Container sx={{ ml: 7, pl: 0 }}>

      {artboards && <Modal centered={true} closeOnEscape={false} closeOnClickOutside={false} withCloseButton={false} opened={snapping} style={{ maxHeight: 85, maxWidth: 200, overflow: 'hidden' }}>
      {<div style={{maxWidth: 200, overflow: 'hidden'}}><Screenshot page={'storyboard'} result={result} removeGroup={removeGroup} index={artboardNumber}  projectKey={projectKey} guestMode={false} artboard={artboardNumber} /></div>}
      <b>Please wait, capturing screenshots... {artboardNumber}/{Object.keys(artboards).length}</b>
      </Modal>}
      <LoadingOverlay visible={snapping}/>
      {/* <Screenshot page={'storyboard'} result={result} removeGroup={removeGroup} index={Number(currentSnappingArtboard)}  projectKey={projectKey} guestMode={false} artboard={Number(currentSnappingArtboard)} /></Modal>} */}
        <Grid container sx={{ pl: 0, mb:2 }}>
          <Grid item xs={12} md={5} sx={{pl:0}}>
            <Typography
              component="h1"
              variant="h4"
              fontWeight="700"
              // align="center"
              color="text.primary"
              gutterBottom
              sx={{pl:0}}
            >
              Edit or share your storyboard
            </Typography>
          </Grid>
         
          <Grid item xs={12} md={7} sx={{pl:0}}>
            <Stack
              sx={{ pt: 1, display: 'flex', justifyContent: 'flex-end' }}
              direction="row" 
              spacing={2}
            >
              <ExportToAPIButton projectKey={projectKey} />
             {artboards && <CopyStoryboardTable />}
              <Button onClick={() => updateAllScreenshots()} 
                size="small" variant="contained"
                style={{ backgroundColor: "#2d9bf0", boxShadow: "none", color: 'black', borderRadius: 0, borderStyle: "outset", padding:4}}>
                Refresh Screenshots</Button>
              <Button onClick={() => navigator.clipboard.writeText(`https://mbxr.io/story/${projectKey}`)} 
                size="small" variant="contained"
                style={{ backgroundColor: "#2d9bf0", boxShadow: "none", color: 'black', borderRadius: 0, borderStyle: "outset", padding:4}}>
                Copy Preview Link</Button>
            </Stack>
          </Grid>
        </Grid> 
      </Container> 
    </Box> 
     
    <Container sx={{ pb: 0, pt: 2, ml: 7, pl: 0 }} >
      <Grid container spacing={2}>
        <Suspense fallback={<img src="/image/loading.gif" style={{ width: "50px", height: "50px" }} />}>
          {objectsAreLoaded && Object.keys(artboards).map((mapped, i) => (
            <Grid item key={mapped} xs={12} sm={6} md={3}>
              <Card radius={16} padding={0} style={{ margin: 10 }}
                shadow="0px 1px 3px 0px rgba(0, 0, 0, 0.05), 0px 1px 2px 0px rgba(0, 0, 0, 0.10)">
                
                  <div>
                    {
                      screenshots[mapped] ? <div
                      style={{
                        width: 300, height: 200,
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundImage: `url(${screenshots[mapped]})`,
                      }}
                      size="small" /> :
                      <div
                        style={{
                          width: 260, height: 100,
                          borderRadius: 0, backgroundColor: '#C1C2C5'
                          // width: 130, height: 85, borderRadius: 0, backgroundColor: '#C1C2C5'
                        }}
                        size="small" />
                    }
                  {/* {result.length > 0 && result[0].includes(mapped) ? (
                   screenshotStatus && <Screenshot page={'storyboard'} result={result} removeGroup={removeGroup} index={i}  projectKey={projectKey} guestMode={false} artboard={mapped} />
                  ) : (
                    <div style={{ height: '100%', width: '100%' }}>
                     <Center maw={244} h={150}>
                        <Loader size={'lg'} color="black" />
                        </Center></div>
                    )} */}
                  {/* {visibleItemsSet.has(i.toString()) ? (
                   screenshotStatus && <Screenshot removeGroup={removeGroup} index={i} updateScreenshotArtboard={updateScreenshotArtboard} snapping={0} projectKey={projectKey} guestMode={false} artboard={mapped} />
                    // <ScreenshotStoryboardPOV index={i} projectKey={projectKey} guestMode={false} artboard={mapped} />
                  ) : (
                    <div style={{ height: '100%', width: '100%' }}>
                     <Center maw={244} h={150}>
                        <Loader size={'lg'} color="black" />
                        </Center></div>
                    )} */}
                  {/* <ScreenshotStoryboardPOV index={i} projectKey={projectKey} guestMode={false} artboard={mapped} /> */}
                  {/* <Screenshot index={i} projectKey={projectKey} guestMode={false} artboard={mapped} /> */}
                </div>
                <CardContent sx={{ flexGrow: 1, pb: 0 }}>
                  <Typography gutterBottom variant="h6" component="h6">
                    Scene: {mapped}
                  </Typography>
                  <StoryboardTextField fieldType="name" isEditable={true} artboard={mapped} />

                  <StoryboardTextField fieldType="description" isEditable={true} artboard={mapped} />

                  <Suspense fallback={null}>
                    <Tooltip title="This is edit mode. Comments are written in preview mode." placement="bottom-start">
                      <span>
                        <StoryboardTextField fieldType="comments" isEditable={false} artboard={mapped} />
                      </span>
                    </Tooltip>
                  </Suspense>
                </CardContent>
                <Group style={{margin:10}}>
                  <Button
                    onClick={() => unloadObjects()}
                    style={{
                      width: '45%', inline: true, backgroundColor: 'black', borderRadius: 32
                    }}
                    component='a'
                    href={`/preview/${projectKey}?artboard=${mapped}`}>
                    View</Button>
                  <Button
                    style={{
                      width: '45%', inline: true, backgroundColor: 'black', borderRadius: 32
                    }}
                    component='a'
                    href={`/editor/${projectKey}?artboard=${mapped}`}>
                    Edit</Button>
                </Group>
              </Card>
            </Grid>
          ))}</Suspense>
      </Grid>
    </Container>
    {/* <Canvas
      eventSource={document.getElementById("root")}
      className="canvas"
      shadows
      style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, overflow: 'hidden' }}
      camera={{
        position: [0, 0, 0],
        fov: 55
      }}
      frameloop="demand"
      gl={{ preserveDrawingBuffer: true }}
      ref={canvasRef}
    >
      <View.Port />
    </Canvas> */}
  </>
  );
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

