import React, { useState } from "react";
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useObjectStore } from "../zustand/objects";
import ModelAttributions from "../ModelAttributions.json";
import Divider from '@mui/material/Divider';
import { getSinglePose } from "../api.js"


const ViewAttribution = ({ projectKey }) => {

    const { artboards } = useObjectStore();



    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }
 
    return (<> 
        <div>
            {/* <button onClick={() => handleOpen()} variant="outlined" style={{ height: "100%", backgroundColor: "white", boxShadow: "none", color: 'black', borderColor: 'black' }}>View creators</button> */}
            <Button onClick={() => handleOpen()} variant="outlined" style={{height: "100%", padding: 4, backgroundColor: "white", boxShadow: "none", color: 'black', borderColor: 'black', borderRadius: 0, borderWidth: 1.5 }}>View creators</Button>
            <Modal 
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Model creator attribution
                    </Typography>
                    {
                    Object.keys(artboards).map((mapped) => artboards[mapped].map(object => object.id !== "camera" && object.category !== "shape" && object.category !== "aiModel" && object.category !== "image" && object.category !== "text" && object.category !== "UI" && object.category !== "figma" && object.category !== "uploaded" && object.category !== "apple" && object.category !== "microsoft" && object.category !== "floatgridsUi" && object.category !== "floatgridsLayout"
                       && (<>
                       <Typography variant="subtitle2">{object.object}</Typography>
                       <Typography display="block">model creator: {ModelAttributions[object.object][object.pose] ? ModelAttributions[object.object][object.pose].creator : ModelAttributions[object.object].creator}</Typography>
                       <Typography>model copyright: {ModelAttributions[object.object][object.pose] ? ModelAttributions[object.object][object.pose].copyright : ModelAttributions[object.object].copyright}</Typography>
                       <Typography>model license: {ModelAttributions[object.object][object.pose] ? ModelAttributions[object.object][object.pose].license : ModelAttributions[object.object].license}</Typography>
                       <Typography>model disclaimer: {ModelAttributions[object.object][object.pose] ? ModelAttributions[object.object][object.pose].disclaimer : ModelAttributions[object.object].disclaimer}</Typography>
                       <Typography>link to original model: {ModelAttributions[object.object][object.pose] ? ModelAttributions[object.object][object.pose].linkToOriginal : ModelAttributions[object.object].linkToOriginal}</Typography>
                       <Divider />
                       </>
                       )

                    ))}
                    <button onClick={() => handleClose()}>Close</button>
                </Box>
                

            </Modal>
        </div>
    </>
    );
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: 'scroll'
};

export default ViewAttribution;
