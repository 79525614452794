import React, { useRef, useState, Suspense, useEffect } from "react";
import { useObjectStore } from "../zustand/objects";
import { PreviewModel, ShapeTexture, PreviewAIModel, PreviewUploadedModel, UIModelTexture } from "./PreviewModel";
import PreviewText from "./PreviewText";
import PreviewImageObject from "./PreviewImageObject";
import {PreviewFigmaObject} from "./FigmaObject";
import { Camera } from "./Camera";
import {ChangePreviewScene} from "./ChangePreviewScene";
import "../dot.css";

import { isAndroid } from 'react-device-detect';
import { Canvas } from "@react-three/fiber";
import { OrbitControls, softShadows, Sky } from "@react-three/drei";
import { XR, Controllers, Interactive } from "@react-three/xr";

// softShadows();

const ThirdPersonPreviewViewport = ({guestMode, projectKey}) => {
  const {
    objectsAreLoaded,
    floorColour,
    updateArtboard, 
    presence,
    artboards,
    currentObjectArtboard
  } = useObjectStore();

  const orbitControls = useRef();

  const [splashClicked, setSplashClicked] = useState(false);

  const changeSplashClick = (e) => {
    setSplashClicked(e)
  }

  const clickedShape = (dest) => {
    dest && updateArtboard(dest)
  };

  const COLORS = [
    "#E57373",
    "#9575CD",
    "#4FC3F7",
    "#81C784",
    "#FFF176",
    "#FF8A65",
    "#F06292",
    "#7986CB",
  ];
  // - - - -  cursors end

  if (!objectsAreLoaded) {
    return <div style={styles.viewport}>Loading...</div>;
  } else {
    {
      if (isAndroid) {
        return <Suspense fallback={<div>Loading... </div>}>
          <Canvas
            gl={{ antialias: true }}
            dpr={window.devicePixelRatio}
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%"
            }}
            frameloop="demand">
            <XR>
              <Controllers />
              <ambientLight intensity={1.5} />
          <group name="sun" position={[0, 10, 100]} rotation={[-Math.PI / 2, 0, 0]}>
            {/* <spotLight matrixAutoUpdate={false} intensity={150} shadow-mapSize-height={2048} shadow-mapSize-width={2048} /> */}
            <directionalLight matrixAutoUpdate={false} intensity={3} shadow-mapSize-height={2048} shadow-mapSize-width={2048} />
          </group>
              <group>
                {artboards && artboards[currentObjectArtboard].map((mapped, i) => (
                  mapped.category !== "text" ?
                    (mapped.object === "camera") ?
                      <Camera
                        key={mapped.id}
                        position={mapped.position}
                        rotation={mapped.rotation}
                        scale={mapped.scale} 
                        id={mapped.id}
                        object={mapped.object}
                        artboard={artboards[currentObjectArtboard]}
                        currentObjectArtboard={currentObjectArtboard}
                        objectsAreLoaded={objectsAreLoaded}
                        matrixState={mapped.matrixState}
                      /> :
                      (mapped.category === "image") ?
                        <Interactive onSelect={() => clickedShape(mapped.destination)}>
                          <PreviewImageObject
                            key={i}
                            position={mapped.position}
                            rotation={mapped.rotation}
                            scale={mapped.scale}
                            id={mapped.id}
                            destination={mapped.destination}
                            object={mapped.object}
                            category={mapped.category}
                            currentObjectArtboard={currentObjectArtboard}
                            objectsAreLoaded={objectsAreLoaded}
                            colour={mapped.colour}
                            pose={mapped.pose}
                            image={mapped.image}
                            imageSize={mapped.imageSize}
                            matrixState={mapped.matrixState}
                          />  </Interactive> :
                        (mapped.category === "figma") ?
                        <Interactive onSelect={() => clickedShape(mapped.destination)}>
                          <PreviewFigmaObject
                            key={i}
                            position={mapped.position}
                            rotation={mapped.rotation}
                            scale={mapped.scale}
                            id={mapped.id}
                            destination={mapped.destination}
                            object={mapped.object}
                            category={mapped.category}
                            currentObjectArtboard={currentObjectArtboard}
                            objectsAreLoaded={objectsAreLoaded}
                            colour={mapped.colour}
                            figmaID={mapped.figmaID}
                            figmaURL={mapped.figmaURL}
                            figmaSize={mapped.figmaSize}
                            matrixState={mapped.matrixState}
                          /> </Interactive>
                          :
                                          mapped.databaseId && (mapped.category === "ai") ?
                          <Interactive onSelect={() => clickedShape(mapped.destination)}>
                          <PreviewAIModel
                          key={mapped.id}
                          position={mapped.position}
                          rotation={mapped.rotation}
                          scale={mapped.scale}
                          id={mapped.id}
                          destination={mapped.destination}
                          object={mapped.object}
                          prompt={mapped.object}
                          category={mapped.category}
                          url={"/model/" + mapped.category + "/" + mapped.object + ".glb"}
                          artboard={artboards[currentObjectArtboard]}
                          currentObjectArtboard={currentObjectArtboard}
                          objectsAreLoaded={objectsAreLoaded}
                          colour={mapped.colour}
                          pose={mapped.pose}
                          imageTexture={mapped.imageTexture}
                          isLocked={mapped.isLocked}
                          matrixState={mapped.matrixState}
                          guestMode={guestMode}
                          projectKey={projectKey}
                          databaseId={mapped.databaseId}
                          databaseURL={mapped.databaseURL}
                        /></Interactive>: (mapped.category === "uploaded") ?
                        <Suspense key={i} fallback={null}>
                        <Interactive key={i} onSelect={() => clickedShape(mapped.destination)}>
                          <PreviewUploadedModel
                        key={mapped.id}
                        position={mapped.position}
                        rotation={mapped.rotation}
                        scale={mapped.scale}
                        id={mapped.id}
                        destination={mapped.destination}
                        object={mapped.object}
                        prompt={mapped.object}
                        category={mapped.category}
                        url={"/model/" + mapped.category + "/" + mapped.object + ".glb"}
                        artboard={artboards[currentObjectArtboard]}
                        currentObjectArtboard={currentObjectArtboard}
                        objectsAreLoaded={objectsAreLoaded}
                        colour={mapped.colour}
                        pose={mapped.pose}
                        imageTexture={mapped.imageTexture}
                        isLocked={mapped.isLocked}
                        matrixState={mapped.matrixState}
                        uploadedURL={mapped.uploadedURL}
                        uploadId={mapped.uploadId}
                      /></Interactive>
                      </Suspense>   :
                  mapped.imageTexture && (mapped.category === "UI") ?
                    <Suspense key={i} fallback={null}>
                      <Interactive key={i} onSelect={() => clickedShape(mapped.destination)}>
                        <UIModelTexture
                          key={mapped.id}
                          position={mapped.position}
                          rotation={mapped.rotation}
                          scale={mapped.scale}
                          id={mapped.id}
                          destination={mapped.destination}
                          object={mapped.object}
                          category={mapped.category}
                          url={"/model/" + mapped.category + "/" + mapped.object + ".glb"}
                          artboard={artboards[currentObjectArtboard]}
                          currentObjectArtboard={currentObjectArtboard}
                          objectsAreLoaded={objectsAreLoaded}
                          colour={mapped.colour}
                          pose={mapped.pose}
                          imageTexture={mapped.imageTexture}
                          matrixState={mapped.matrixState}
                        />
                      </Interactive>
                    </Suspense>
                    :
                    (mapped.imageTexture && mapped.category === "shape") ?
                      <Suspense key={i} fallback={null}>
                        <Interactive key={i} onSelect={() => clickedShape(mapped.destination)}>
                          <ShapeTexture
                            key={mapped.id}
                            position={mapped.position}
                            rotation={mapped.rotation}
                            scale={mapped.scale}
                            id={mapped.id}
                            destination={mapped.destination}
                            object={mapped.object}
                            category={mapped.category}
                            url={"/model/" + mapped.category + "/" + mapped.object + ".glb"}
                            artboard={artboards[currentObjectArtboard]}
                            currentObjectArtboard={currentObjectArtboard}
                            objectsAreLoaded={objectsAreLoaded}
                            colour={mapped.colour}
                            pose={mapped.pose}
                            imageTexture={mapped.imageTexture}
                            matrixState={mapped.matrixState}
                          />
                        </Interactive>
                      </Suspense>
                      :
                      <Suspense key={i} fallback={null}>
                        <Interactive key={i} onSelect={() => clickedShape(mapped.destination)}>
                          <PreviewModel
                            key={i}
                            position={mapped.position}
                            rotation={mapped.rotation}
                            scale={mapped.scale}
                            id={mapped.id}
                            destination={mapped.destination}
                            object={mapped.object}
                            category={mapped.category}
                            url={"/model/" + mapped.category + "/" + mapped.object + ".glb"}
                            artboard={artboards[currentObjectArtboard]}
                            currentObjectArtboard={currentObjectArtboard}
                            objectsAreLoaded={objectsAreLoaded}
                            colour={mapped.colour}
                            pose={mapped.pose}
                            imageTexture={mapped.imageTexture}
                            matrixState={mapped.matrixState}
                            curved={mapped.curved}
                            curveAmount={mapped.curveAmount}
                            modelPath={mapped.modelPath}
                          />
                        </Interactive>
                      </Suspense>
        :
        <Suspense key={i} fallback={null}>
          <Interactive key={i} onSelect={() => clickedShape(mapped.destination)}>
            <PreviewText
              key={i}
              content={mapped.content}
              position={mapped.position}
              rotation={mapped.rotation}
              scale={mapped.scale}
              id={mapped.id}
              destination={mapped.destination}
              artboard={artboards[currentObjectArtboard]}
              currentObjectArtboard={currentObjectArtboard}
              objectsAreLoaded={objectsAreLoaded}
              colour={mapped.colour}
              bGColour={mapped.bGColour}
              matrixState={mapped.matrixState}
            />
          </Interactive>
        </Suspense>
                ))}
              </group>
             
              <mesh
                receiveShadow
                rotation={[-Math.PI / 2, 0, 0]}
                position={[0, -0.5, 0]}
              >
                <planeGeometry attach="geometry" args={[50, 50]} />
                <meshBasicMaterial
                  color={floorColour}
                  attach="material"
                  opacity={1}
                />
              </mesh>
              <gridHelper position={[0, -0.5, 0]} args={[50, 50]} />
            </XR></Canvas></Suspense>
      }
      else return <><Suspense fallback={<div>Loading... </div>}>
        <Canvas
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%"
          }}
          camera={{
            position: [0, 15, 15],
            fov: 25
          }}
          frameloop="demand">
          <OrbitControls
            maxPolarAngle={1.5}
            dampingFactor={1}
            keyPanSpeed={15}
            zoomSpeed={0.5}
            maxDistance={50}
            minDistance={5}
            enableZoom={true}
            enableDamping={true}
            ref={orbitControls}
          />
          <Sky distance={450000} sunPosition={[0, 1, 0]} inclination={0} azimuth={0.25} />
          <ambientLight intensity={1.5} />
          <group name="sun" position={[0, 10, 100]} rotation={[-Math.PI / 2, 0, 0]}>
            {/* <spotLight matrixAutoUpdate={false} intensity={150} shadow-mapSize-height={2048} shadow-mapSize-width={2048} /> */}
            <directionalLight matrixAutoUpdate={false} intensity={3} shadow-mapSize-height={2048} shadow-mapSize-width={2048} />
          </group>
          <group>
          {/* {artboards && <ChangePreviewScene/>} */}
            {(artboards !== null) && artboards[currentObjectArtboard].map((mapped, i) => (
              mapped.category !== "text" ?
                (mapped.object === "camera") ?'' :
                  (mapped.category === "image") ?
                  <PreviewImageObject
                  key={i}
                  position={mapped.position}
                  rotation={mapped.rotation}
                  scale={mapped.scale}
                  id={mapped.id}
                  destination={mapped.destination}
                  object={mapped.object}
                  category={mapped.category}
                  currentObjectArtboard={currentObjectArtboard}
                  objectsAreLoaded={objectsAreLoaded}
                  colour={mapped.colour}
                  pose={mapped.pose}
                  image={mapped.image}
                  imageSize={mapped.imageSize}
                  matrixState={mapped.matrixState}
                  curved={mapped.curved}
                  curveAmount={mapped.curveAmount}
                /> : (mapped.category === "figma") ?
                  <PreviewFigmaObject
                    key={i}
                    position={mapped.position}
                    rotation={mapped.rotation}
                    scale={mapped.scale}
                    id={mapped.id}
                    destination={mapped.destination}
                    object={mapped.object}
                    category={mapped.category}
                    currentObjectArtboard={currentObjectArtboard}
                    objectsAreLoaded={objectsAreLoaded}
                    colour={mapped.colour}
                    figmaID={mapped.figmaID}
                    figmaURL={mapped.figmaURL}
                    figmaSize={mapped.figmaSize}
                    matrixState={mapped.matrixState}
                    curved={mapped.curved}
                    curveAmount={mapped.curveAmount}
                  /> :
                                  mapped.databaseId && (mapped.category === "ai") ?
                    <PreviewAIModel
                      key={mapped.id}
                      position={mapped.position}
                      rotation={mapped.rotation}
                      scale={mapped.scale}
                      id={mapped.id}
                      destination={mapped.destination}
                      object={mapped.object}
                      prompt={mapped.object}
                      category={mapped.category}
                      url={"/model/" + mapped.category + "/" + mapped.object + ".glb"}
                      artboard={artboards[currentObjectArtboard]}
                      currentObjectArtboard={currentObjectArtboard}
                      objectsAreLoaded={objectsAreLoaded}
                      colour={mapped.colour}
                      pose={mapped.pose}
                      imageTexture={mapped.imageTexture}
                      isLocked={mapped.isLocked}
                      matrixState={mapped.matrixState}
                      guestMode={guestMode}
                      aiKey={mapped.aiKey}
                      projectKey={projectKey}
                      databaseId={mapped.databaseId}
                      databaseURL={mapped.databaseURL}
                    /> : (mapped.category === "uploaded") ?
                      <PreviewUploadedModel
                        key={mapped.id}
                        position={mapped.position}
                        rotation={mapped.rotation}
                        scale={mapped.scale}
                        id={mapped.id}
                        destination={mapped.destination}
                        object={mapped.object}
                        prompt={mapped.object}
                        category={mapped.category}
                        url={"/model/" + mapped.category + "/" + mapped.object + ".glb"}
                        artboard={artboards[currentObjectArtboard]}
                        currentObjectArtboard={currentObjectArtboard}
                        objectsAreLoaded={objectsAreLoaded}
                        colour={mapped.colour}
                        pose={mapped.pose}
                        imageTexture={mapped.imageTexture}
                        isLocked={mapped.isLocked}
                        matrixState={mapped.matrixState}
                        uploadedURL={mapped.uploadedURL}
                        uploadId={mapped.uploadId}
                      /> :
                      mapped.imageTexture && (mapped.category === "UI") ?
                        <UIModelTexture
                          key={mapped.id}
                          position={mapped.position}
                          rotation={mapped.rotation}
                          scale={mapped.scale}
                          id={mapped.id}
                          destination={mapped.destination}
                          object={mapped.object}
                          category={mapped.category}
                          url={"/model/" + mapped.category + "/" + mapped.object + ".glb"}
                          artboard={artboards[currentObjectArtboard]}
                          currentObjectArtboard={currentObjectArtboard}
                          objectsAreLoaded={objectsAreLoaded}
                          colour={mapped.colour}
                          pose={mapped.pose}
                          imageTexture={mapped.imageTexture}
                          isLocked={mapped.isLocked}
                          matrixState={mapped.matrixState}
                        />
                        :
                        (mapped.imageTexture && mapped.category === "shape") ?
                          <ShapeTexture
                            key={i}
                            position={mapped.position}
                            rotation={mapped.rotation}
                            scale={mapped.scale}
                            id={mapped.id}
                            destination={mapped.destination}
                            object={mapped.object}
                            category={mapped.category}
                            url={"/model/" + mapped.category + "/" + mapped.object + ".glb"}
                            artboard={artboards[currentObjectArtboard]}
                            currentObjectArtboard={currentObjectArtboard}
                            objectsAreLoaded={objectsAreLoaded}
                            colour={mapped.colour}
                            pose={mapped.pose}
                            imageTexture={mapped.imageTexture}
                            matrixState={mapped.matrixState}
                          /> :
  
                          <PreviewModel
                            key={i}
                            position={mapped.position}
                            rotation={mapped.rotation}
                            scale={mapped.scale}
                            id={mapped.id}
                            destination={mapped.destination}
                            object={mapped.object}
                            category={mapped.category}
                            url={"/model/" + mapped.category + "/" + mapped.object + ".glb"}
                            artboard={artboards[currentObjectArtboard]}
                            currentObjectArtboard={currentObjectArtboard}
                            objectsAreLoaded={objectsAreLoaded}
                            colour={mapped.colour}
                            pose={mapped.pose}
                            imageTexture={mapped.imageTexture}
                            matrixState={mapped.matrixState}
                            curved={mapped.curved}
                            curveAmount={mapped.curveAmount}
                            modelPath={mapped.modelPath}
                          /> :
            <PreviewText
              key={i}
              content={mapped.content}
              position={mapped.position}
              rotation={mapped.rotation}
              scale={mapped.scale}
              id={mapped.id}
              destination={mapped.destination}
              artboard={artboards[currentObjectArtboard]}
              currentObjectArtboard={currentObjectArtboard}
              objectsAreLoaded={objectsAreLoaded}
              colour={mapped.colour}
              bGColour={mapped.bGColour}
              matrixState={mapped.matrixState}
            />
  
            ))}
          </group>


          <mesh
            receiveShadow
            rotation={[-Math.PI / 2, 0, 0]}
            position={[0, -0.5, 0]}
          >
            <planeGeometry attach="geometry" args={[50, 50]} />
            <meshBasicMaterial
              color={floorColour}
              attach="material"
              opacity={1}
            />
          </mesh>
          <gridHelper position={[0, -0.5, 0]} args={[50, 50]} />
        </Canvas>
      </Suspense>
      </>
    };
  }
};

const styles = {
  splash: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: "0%",
    left: "0%",
    outline: "none",
    background: "black",
    opacity: "80%"
  },
  splashText: {
    color: "white",
    textAlign: "center",
    paddingTop: "25%"
  },
  splashButton: {
    position: "absolute",
    width: "10%",
    height: "5%",
    left: "45%",
    top: "47.5%"
  },
  wasdImage: {
    width: "12.5%",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  button: {
    position: "absolute",
    width: "10%",
    height: "10%",
    top: "50%",
    left: "50%",
    color: "white",
  }
};

export default ThirdPersonPreviewViewport;
