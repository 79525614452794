import { checkAuthTokenAndSubscription, getStripeSubStatus, checkAuthToken, sendUserLogin, createNewProjectRecord, getUserProjectsAPI, deleteSpecificUserProjectAPI } from "../api";
import {create} from "zustand";
import produce from "immer";
import * as THREE from "three";
import { FastfoodOutlined } from "@mui/icons-material";
  
// ~~~~~~~~~~~~~~~~~~~~~~~~
// STATE
// ~~~~~~~~~~~~~~~~~~~~~~~~

// const artboards = useObjectStore.getState().artboards
// const screenshots = useObjectStore.getState().screenshots
// const storyboardFields = useObjectStore.getState().storyboardFields
// const floorColour = useObjectStore.getState().floorColour

const store = (set) => ({
    // SESSION AUTH
    helpOpen: false,
    figmaState: '000',
    isAuthUser: false,
    stripeStatus: 'active',
    userStatus: 'loggedOut',
    tutorialSeen: {
        projects: false,
        editor: false,
        storyboard: false,
        preview: false
    },
    isEditor: true,
    hasAccess: false,
    isTempUser: false,
    isEditing: false,
    isPreviewRestricted: false,
    accountID: '',
    authToken: '',
    tempToken: '',
    email: '',
    firstName: '',
    lastName: '',
    projects: null, 
    guestProjects: [], 
    deleteID: '',
    deleteModalOpen: false,
    // CHECK YOU'VE GOT CREDS
    loginChecked: false,
     // UPDATE FIRST NAME
     updateFirstName: (name) =>
     set((state) => {
         state.firstName = name
     }),
    // UPDATE FIGMA STATE
    updateFigmaState: (val) =>
        set((state) => {
            state.figmaState = val
        }),
    // UPDATE FIGMA STATE
    setHelpOpen: (val) =>
        set((state) => {
            state.helpOpen = val
        }),
     // UPDATE DELETE MODAL OPEN
    updateDeleteModalOpen: () =>
        set((state) => {
            state.deleteModalOpen = !state.deleteModalOpen
        }),
    // UPDATE DELETE ID
    updateDeleteID: (id) =>
        set((state) => {
            state.deleteID = id
        }),
    // UPDATE AUTH TOKEN
    updateAuthToken: (token) =>
        set((state) => {
            state.authToken = token
        }),
    // UPDATE AUTH TOKEN
    updateStripeStatus: (status) =>
        set((state) => {
            state.stripeStatus = status
        }), 
    // VERIFY LOGIN INFO
    sendLoginInfo: ({ email, password }) =>
        sendUserLogin({ email, password })
            .then((res) =>
               res.status === 'authorised' && set((state) => {
                    state.isAuthUser = true
                    localStorage.setItem('user', res.authToken)
                    localStorage.setItem('firstName', res.userName)
                }))
            ,
    // VERIFY AUTH TOKEN
    checkAuthTokenValid: () =>
            checkAuthToken()
            .then(response =>
                set((state) => {
                    if (JSON.stringify(response.code) === '"ERROR_CODE_UNAUTHORIZED"') {
                        state.isAuthUser = false
                        localStorage.removeItem("user")
                        localStorage.removeItem("firstName")
                        state.loginChecked = true
                    }
                    else {
                        state.isAuthUser = true
                        state.loginChecked = true
                        state.userStatus = 'loggedIn'
                    }
                })
            )
            .catch(err =>
                set((state) => {
                    state.isAuthUser = false 
                    // console.log(err)
                })
            ),
    // VERIFY AUTH TOKEN AND SUBSCRIPTION
    checkAuthTokenValidSubscription: () =>
        checkAuthTokenAndSubscription()
            .then(response =>
                set((state) => {
                    if (response.user === true) {
                        // console.log(response)
                        state.isAuthUser = response.user
                        state.userStatus = response.status
                        state.stripeStatus = response.subscription
                        state.loginChecked = true
                    }
                    else {
                        localStorage.removeItem("user")
                        localStorage.removeItem("firstName")
                        state.isAuthUser = false
                        state.loginChecked = true
                    }
                })
            )
            .catch(err =>
                set((state) => {
                    state.isAuthUser = false
                    localStorage.removeItem("user")
                    state.loginChecked = true
                })
            ),
    // CHECK STRIPE STATUS
    checkStripeStatus: () =>
        getStripeSubStatus()
            .then(response =>
                console.log(response)
            )
            .catch(err =>
                set((state) => {
                    state.stripeStatus = null
                    // console.log(err)
                })
            ),
    // CHECK AUTH TOKEN AND STRIPE SUBSCRIPTION
    validateAccess: () =>
    checkAuthToken()
            .then(response =>
                set((state) => {
                    if (JSON.stringify(response.code) === '"ERROR_CODE_UNAUTHORIZED"') {
                        state.isAuthUser = false
                        localStorage.removeItem("user")
                        state.loginChecked = true
                        state.userStatus = 'loggedOut'
                    }
                    else {
                        state.isAuthUser = true
                        state.loginChecked = true
                        state.userStatus = 'loggedIn'
                    }

                })
        // ).then(() => window.location.pathname !== "/logging-in" && getStripeSubStatus()).then(data =>
        ).then(() => getStripeSubStatus()).then(data =>
            set((state) => {
                state.stripeStatus = data
                state.userStatus = `loggedIn-${data}`
                // state.isAuthUser && (data !== 'active') 
                // && window.location.pathname !== "/membership"
                // && window.location.replace('http://localhost:3000/membership')
            }))
            .catch(err =>
                set((state) => {
                    console.log(err)
                    // state.stripeStatus = null
                    // state.isAuthUser = false
                })
            )
    ,
            // ,
    // checkAuthTokenValid: (authenticated) =>
    //         // checkAuthToken()
    //         // .then(response =>
    //             set((state) => {
    //                 // if (JSON.stringify(response.code) === '"ERROR_CODE_UNAUTHORIZED"') {
    //                 if (authenticated) {
    //                     state.isAuthUser = true
    //                     // localStorage.removeItem("user")
    //                     state.loginChecked = true
    //                 }
    //                 // if (JSON.stringify(response.code) === '"ERROR_CODE_UNAUTHORIZED"') {
    //                 //     state.isAuthUser = false
    //                 //     localStorage.removeItem("user")
    //                 //     state.loginChecked = true
    //                 // }
    //                 else {
    //                     state.isAuthUser = false
    //                     state.loginChecked = true
    //                 }

    //             })
            // )
            // .catch(err =>
            //     set((state) => {
            //         state.isAuthUser = false
            //         // console.log(err)
            //     })
            // )
            // VERIFY TEMP INFO
    sendTempInfo: ({ projectKey, password }) =>
    sendUserLogin({ projectKey, password })
        .then(() =>
            set((state) => {
                state.isTempUser = true
            })
        ),
    // LOG OUT
    logUserOut: () =>
        set((state) => {
            state.isAuthUser = false
            localStorage.removeItem('user')
        }),
    // CREATE A PROJECT
    createNewProject: (name) =>
        set((state) => {
            createNewProjectRecord({
                name: name,
                artboards: { 1: [] },
                // {1: ""}, 
                storyboardFields: {
                    1: {
                        "description": "",
                        "comments": "",
                        "name": ""
                    }
                },
                floorColour: "white"
            })
            state.isEditing = true
        }),
    // GET ALL USER'S PROJECTS
    getUserProjects: () =>
        getUserProjectsAPI(localStorage.getItem('user'))
            .then(response =>
                set((state) => {
                    state.projects = response

                }))
                ,
    // DELETE A SPECIFIC USER PROJECT
    deleteSpecificUserProject: (id) =>
        deleteSpecificUserProjectAPI(id)
            .then(response => ''),
})
const immer = (config) => (set, get, api) =>
    config((fn) => set(produce(fn)), get, api);
export const useAccountStore = create(immer(store));
