import React, { useEffect, useState, useMemo } from "react";
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { useLocation, useParams } from "react-router-dom";
import PreviewViewport from "../components/PreviewViewport";
import ThirdPersonPreviewViewport from "../components/ThirdPersonPreviewViewport";
import { useObjectStore } from "../zustand/objects";
import SwitchToPreviewButton from "../components/SwitchToPreviewButton";
import SwitchVRMode from "../components/SwitchVRMode";
import StoryboardLinkButton from "../components/StoryboardLinkButton";
import ChangePreviewArtboard from "../components/ChangePreviewArtboard";
import { useGLTF } from '@react-three/drei'
import { getModelURLs } from '../api.js';
import * as THREE from "three";
import debounce from 'lodash.debounce';

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const PreviewPage = ({ match }) => {

  let query = useQuery();

  const queryString = query.get("artboard")

  const {  removePresence, currentObjectArtboard, presence, uniqueRealtimeId, addOrUpdatePresence, joinChannel, xanoClient, realtimeEventUpdate, updateArtboard, getSpecificUserProject, unloadObjects, loadObjects, previewVR, objectsAreLoaded, floorColour, artboards, getAIModels } = useObjectStore();

  const { projectKey } = useParams();


  async function unloadProject() {
    unloadObjects()

  }
  
  const [modelURLs, setModelURLs] = useState()



  useEffect(() => {
    unloadProject().then(() => 
    getSpecificUserProject(projectKey)).then(() => 
    getAIModels())
    .then(result => loadObjects())
    .then(res =>
      queryString ? updateArtboard(queryString) : updateArtboard('1'))
      // .then(() => prepareModelURLs())

  }, []);

  // XANO REALTIME SECTION
  const [realtime, setRealtime] = useState(false)

  const joinFunction = (action) => {
    setRealtime(true) 
    joinChannel({projectKey: projectKey, preview: {
        rotation: new THREE.Euler(), 
        position: new THREE.Vector3(), 
        mouse: new THREE.Vector2(), 
        page: 'preview', 
        device: 'computer', 
        artboard: currentObjectArtboard, 
        name: localStorage.getItem('firstName') 
      }})
  }

  
  
  try {
    // declare channel
    const projectChannel = xanoClient.channel(`project/${projectKey}`, {presence:  true});
    // check for connection
    projectChannel.on("connection_status", (action) => {
      action.payload.status === "connected" && joinFunction(action)
      // Send a message to only other authenticated users in a channel
    })
     // react to someone leaving
     projectChannel.on("presence_update", (action) => {
      debounce(removePresence(action.payload.presence.socketId), 100)
    })    
    // listen for messages
    realtime && projectChannel.on("message", (action) => {
      if (action.options.type === "projectUpdate") {debounce(realtimeEventUpdate(action), 100)}
      else if (action.options.type === "presenceUpdate") {
        if (uniqueRealtimeId !== action.payload.uniqueRealtimeId) {debounce(addOrUpdatePresence(action), 20)
      }
      }
    
    })

  } catch (err) {
    console.log(err)
  }

  // END XANO REALTIME SECTION
  

  return previewVR ?
    <>
      {objectsAreLoaded && artboards && currentObjectArtboard && <PreviewViewport presence={presence} uniqueRealtimeId={uniqueRealtimeId} floorColour={floorColour} artboards={artboards} projectKey={projectKey} />}
      <Toolbar color="transparent" style={{ display: "flex", justifyContent: "end", background: 'transparent', boxShadow: 'none' }} >
        <Box>
          <Stack direction={"row"} spacing={1}>
            <Box style={{ backgroundColor: "white", borderRadius: 4, display: "flex" }} >
              <StoryboardLinkButton projectKey={projectKey} />
              <SwitchVRMode />
              <SwitchToPreviewButton mode={"preview"} projectKey={projectKey} />
            </Box>
          </Stack>
        </Box>
      </Toolbar>

    </>
    : 
    <>
      {objectsAreLoaded && artboards && currentObjectArtboard && <ThirdPersonPreviewViewport presence={presence} uniqueRealtimeId={uniqueRealtimeId} floorColour={floorColour} artboards={artboards} projectKey={projectKey}  />}
      <Toolbar color="transparent" style={{ display: "flex", justifyContent: "end", background: 'transparent', boxShadow: 'none' }} >
        <Box>
          <Stack direction={"row"} spacing={1}>
            <Box style={{ backgroundColor: "white", borderRadius: 4, display: "flex" }} >
              <StoryboardLinkButton projectKey={projectKey} />
              <Divider orientation="vertical" variant="middle" flexItem style={{ height: "20px" }} />
              <SwitchVRMode />
              <Divider orientation="vertical" variant="middle" flexItem style={{ height: "20px" }} />
              <SwitchToPreviewButton mode={"preview"} projectKey={projectKey} />
              <Divider orientation="vertical" variant="middle" flexItem style={{ height: "20px" }} />
              <ChangePreviewArtboard />
            </Box>
          </Stack>
        </Box>
      </Toolbar>
    </>

};

export default PreviewPage;
