import React, { useState, useEffect } from "react";
import ImageIcon from '@mui/icons-material/Image';

import { Box } from "@mui/material/";
import { ActionIcon, Tooltip } from '@mantine/core';


const StoryboardLinkButton = ({ projectKey, preview }) => {

    const [show, setShow] = useState(false);
    const [showText, setShowText] = useState(false);
    const [divWidth, setDivWidth] = useState(60);
    const [hover, setHover] = useState(false);

    useEffect(() => {
        show ? setDivWidth(120) : setDivWidth(60)
    })

    const handleClose = () => {
        setShow(false);
        setShowText(false);
    };

    return (!preview ? <>
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                height: "100%",
                justifyContent: 'flex-start',
                margin: "2.5px",
                paddingBottom: 0.5
            }}
        >
            <Tooltip label="Export storyboards"><ActionIcon
            style={{marginLeft:10}}
            size={'xs'}
                component='a'
                href={`/storyboard-editor/${projectKey}`}
                target="_blank"
                variant="transparent"
                c={!hover ? "#424242" : "#868e96"}
                onPointerOver={() => setHover(true)}
                onPointerLeave={() => setHover(false)}
                aria-label="View storyboard">
                <ImageIcon  fontSize="small"/>
            </ActionIcon></Tooltip>
        </Box>
        {/* <div style={{ height: "100%", width: divWidth, transition: "width 0.2s", height: "100%", alignItems: "center" }} onMouseEnter={() => setShow(true)} onMouseLeave={() => handleClose()} onTransitionEnd={() => show && !showText && setShowText(!showText)} >
            <Button startIcon={<ImageIcon />}  style={{ backgroundColor: "white", boxShadow: "none", color: 'black', height: "100%" }} size="small" component={Link} to={`/storyboard-editor/${projectKey}`}>
                <div style={{ display: showText ? "inline" : "none", transitionProperty: "display", transitionDelay: "0.2s" }}>Storyboard</div></Button>
        </div> */}
    </>
        :
        <>

            
                <ActionIcon
                 style={{marginLeft:10}}
                size={'sm'}
                    component='a'
                    href={`/${projectKey}`}
                    target="_blank"
                    variant="transparent"
                    color='black'
                    aria-label="View storyboard">
                    <ImageIcon />
                </ActionIcon>





        </>)
};

export default StoryboardLinkButton;
