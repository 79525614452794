import React, { useState, useEffect } from "react";
import { useObjectStore } from "../zustand/objects";
import Box from '@mui/material/Box';
import LanguageIcon from '@mui/icons-material/Language';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Tooltip, ActionIcon } from '@mantine/core';


const SwitchToPreviewButton = () => {
  const {
    previewVR,
    switchVRMode
  } = useObjectStore();
  const [helpHover, setHelpHover] = useState(false)

  const [show, setShow] = useState(false);
  const [showText, setShowText] = useState(false);
  const [divWidth, setDivWidth] = useState(50);

  useEffect(() => {
    show ? setDivWidth(180) : setDivWidth(60)
  })

  const handleClose = () => { 
    setShow(false);
    setShowText(false);
  };

  return (
    <Box
    sx={{
        display: 'flex',
        alignItems: 'center',
        height: "100%",
        justifyContent: 'flex-start',
        margin: "2.5px",
        paddingBottom: 0.5
    }}
>
   {previewVR ?
    <Tooltip label="Switch to third person">
    <ActionIcon
    size={"xs"}
    variant='transparent'
    color={!helpHover ? "#424242" : "#868e96"}
    onPointerOver={() => setHelpHover(true)}
    onPointerLeave={() => setHelpHover(false)}
    onClick={() => switchVRMode()}><LanguageIcon  fontSize="small"/></ActionIcon></Tooltip>
                      :
    <Tooltip label="Switch to first person">
    <ActionIcon
    size={"xs"}
    variant='transparent'
    color={!helpHover ? "#424242" : "#868e96"}
    onPointerOver={() => setHelpHover(true)}
    onPointerLeave={() => setHelpHover(false)}
    onClick={() => switchVRMode()}><VisibilityIcon  fontSize="small"/></ActionIcon></Tooltip>}
    </Box>
  );
};

export default SwitchToPreviewButton;
