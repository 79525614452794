import React, {useState, useEffect} from "react";
import { useObjectStore } from "../zustand/objects";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import EditIcon from '@mui/icons-material/Edit';

import { Box } from "@mui/material/";
import { ActionIcon, Tooltip} from '@mantine/core';


const SwitchToPreviewButton = ({mode, projectKey}) => { 

  const {
    switchModes,
    currentObjectArtboard
  } = useObjectStore();

   
  const [show, setShow] = useState(false);
  const [showText, setShowText] = useState(false);
  const [divWidth, setDivWidth] = useState(50);
  const [hover, setHover] = useState(false);

  useEffect(() => {
    show ? setDivWidth(110) : setDivWidth(70)
  })

  const handleClose = () => {
    setShow(false);
    setShowText(false);
  };

  return (


    <>
    <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                height: "100%",
                justifyContent: 'flex-start',
                margin: "2.5px",
                paddingBottom: 0.5
            }}
        >
            <Tooltip label={(mode === "editor") ? "Preview in first person" : "Edit project"}>
            <ActionIcon
            style={{marginLeft:10}}
            size={'xs'}
            c={!hover ? "#424242" : "#868e96"}
            onPointerOver={() => setHover(true)}
            onPointerLeave={() => setHover(false)}
            onClick={() => {
              switchModes();
            }}
                component='a'
                href={mode === "editor" ? `/preview/${projectKey}?artboard=${currentObjectArtboard}` : `/editor/${projectKey}?artboard=${currentObjectArtboard}`}
                target="_blank"
                variant="transparent"
                aria-label="View storyboard">
                { (mode === "editor") ? <PlayArrowIcon fontSize="small" /> : <EditIcon  fontSize="small" />}
            </ActionIcon></Tooltip>
        </Box>
    </>

  );
};

export default SwitchToPreviewButton;
